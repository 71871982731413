<template>
    <div class="password-box" v-if="!validPassword">
        <h6>Password requirements</h6>
        <ul>
            <li v-if="!characters">At least 12 characters</li>
            <li v-if="!capital">At least one uppercase</li>
            <li v-if="!lowercase">At least one lowercase</li>
            <li v-if="!symbol">At least one symbol</li>
            <li v-if="!digit">At least one number</li>
        </ul>
    </div>
</template>
<script>
    export default {
        props: {
            password: {
                required: true,
            },
        },
        data() {
            return {
                characters: false,
                capital: false,
                lowercase: false,
                symbol: false,
                digit: false,
                validPassword: false,
            };
        },
        methods: {
            passwordRegexCheck(password) {
                let charactersRegex = /(.{12,})+/;
                let capitalRegex = /([A-Z])+/;
                let lowercaseRegex = /([a-z])+/;
                let symbolRegex = /([*.!@#$%^&(){}[:;<>,.?~_+\-=|/\\])+/;
                let digitRegex = /([0-9])+/;
                if (charactersRegex.test(password)) {
                    this.characters = true;
                } else {
                    this.characters = false;
                    this.validPassword = false;
                }
                if (capitalRegex.test(password)) {
                    this.capital = true;
                }  else {
                    this.capital = false;
                    this.validPassword = false;
                }
                if (lowercaseRegex.test(password)) {
                    this.lowercase = true;
                } else {
                    this.lowercase = false;
                    this.validPassword = false;
                }
                if (symbolRegex.test(password)) {
                    this.symbol = true;
                } else {
                    this.symbol = false;
                    this.validPassword = false;
                }
                if (digitRegex.test(password)) {
                    this.digit = true;
                } else {
                    this.digit = false;
                    this.validPassword = false;
                }
                if (this.characters && this.capital && this.lowercase && this.symbol && this.digit) {
                    this.validPassword = true;
                }
            },
        },
        watch: {
            password(password) {
                this.passwordRegexCheck(password);
            },
        },
    }
</script>
<style>
.password-box {
    background-color: #8ebb139f;
    padding: 0.4rem;
    border-radius: 0rem 1rem 1rem 1rem;
    width: 60%;
}
ul {
    margin-bottom: 0px;
    color: #000;
    font-size: 0.8rem;
}

h6 {
    color: #000;
    font-size: 0.8rem;
    margin: 0px;
}
</style>