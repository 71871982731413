<template>
    <BoxLayout>
        <form v-on:submit.prevent="register" v-if="isValid && !isSuccess">
            <div class="mb-2">
                <TextInput
                    label="First name"
                    v-model="v$.first_name.$model"
                    :v="v$.first_name"
                />
            </div>
            <div class="mb-2">
                <TextInput
                    label="Last name"
                    v-model="v$.last_name.$model"
                    :v="v$.last_name"
                />
            </div>
            <div class="mb-2">
                <TextInput
                label="Password"
                v-model="v$.password.$model"
                :v="v$.password"
                type="password"
                @on-click="showPasswordHelper = true"
                />
            </div>
            <div class="mb-2">
                <PasswordModal
                v-show="showPasswordHelper"
                :password="v$.password.$model"
                />
            </div>
            <div class="mb-2">
                <TextInput
                label="Confirm password"
                v-model="v$.password_confirm.$model"
                :v="v$.password_confirm"
                type="password"
                />
            </div>
            <button type="submit" class="btn w-100 btn-primary">Register</button>
        </form>
        <div v-if="!isValid" class="text-center">
            <p>This invitation is no longer valid.</p>
            <p>Please request a new invitation or <router-link to="/login">sign in</router-link> if you have successfully registered with this invitation previously.</p>
        </div>
        <div v-if="isSuccess" class="text-center">
            <p>You have successfully registered for Swift Connect.</p>
            <p>Please <router-link to="/login">sign in</router-link> using the details you provided. </p>
        </div>
    </BoxLayout>
</template>

<script>
    import { ref } from 'vue';
    import api from "@/services/api";
    import BoxLayout from '@/components/BoxLayout.vue';
    import TextInput from '@/components/TextInput.vue';
    import PasswordModal from '@/components/PasswordModal.vue';
    
    import useVuelidate from '@vuelidate/core'
    import { required, sameAs } from '@vuelidate/validators'

    export default {
        props: {
            code: {
                required: true
            }
        },
        data() {
            return {
                first_name: '',
                last_name: '',
                password: '',
                password_confirm: '',
                alertMessage: null,
                isSuccess: false,
                isValid: true,
                showPasswordHelper: false,
            }
        },
        setup () {
            const externalResults = ref({});
            return {
                externalResults,
                v$: useVuelidate({ $externalResults: externalResults }),
            }
        },
        components: {
            BoxLayout,
            TextInput,
            PasswordModal
        },
        validations () {
            return {
                first_name: { required },
                last_name: { required },
                password: { required },
                password_confirm: { required, sameAs: sameAs(this.password) },
            }
        },
        methods: {
            register: async function () {
                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    this.requestRegister();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }
            },
            requestRegister: function () {
                api.post('/api/v1/register', {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    password: this.password,
                    invitation: this.code,
                })
                .then(() => {
                    this.isSuccess = true;
                })
                .catch((error) => {
                    if(error.response)
                    {
                        Object.assign(this.externalResults, error.response.data.errors)
                    }
                    else
                    {
                        this.alertMessage = 'There was an problem signing in. Please try again later.';
                    }
                });
            },
            checkInvitation: function () {
                api
                .get('/api/v1/invitation/' +  this.code)
                .then(() => {
                    // Responded OK
                })
                .catch(() => {
                    this.isValid = false;
                });
            },
        },
        mounted () {
            if(this.$store.state.isSignedIn)
            {
                this.$store.dispatch('signOut')
                .then(() => {
                    this.checkInvitation();
                });
            }
            else
            {
                this.checkInvitation();
            }
        },
    }
</script>